/* GLOBAL CSS */
* {
    font-family: 'Montserrat', sans-serif !important;
    font-optical-sizing: auto;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
        border: 2px solid transparent;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }

    /* Custom scrollbar for Firefox */
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;

    /* Custom scrollbar for Edge/IE */
    -ms-overflow-style: none; /* Hide default scrollbar */
}
